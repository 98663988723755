@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

html {
  height: 100%;
}

body {
  margin: 0;
  color: #252525;
  height: 100%;
  font-family: "Oxygen", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  overflow-x: hidden;
}

@font-face {
  font-family: "MicroExtendFLF";
  src: url("./fonts/Microextendflf.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "MicroExtendFLF";
  src: url("./fonts/MicroextendflfBold.woff") format("woff");
  font-style: bold;
  font-weight: 700;
}

@font-face {
  font-family: "MicroExtendFLF";
  src: url("./fonts/MicroextendflfItalic.woff") format("woff");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "MicroExtendFLF";
  src: url("./fonts/MicroextendflfBolditalic.woff") format("woff");
  font-style: italic;
  font-weight: 700;
}

.swiper-pagination-bullet {
  margin: 0 16px !important;
}

.swiper-pagination-bullet-active {
  background: white !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: white !important;
  opacity: 0.5;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  display: none;
}
